import ElementPlus from "element-plus";
import "boxicons/css/boxicons.min.css";
import "@/styles/element-ui.scss";
import "element-plus/theme-chalk/display.css";
import es from "element-plus/dist/locale/es";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

export default {
  install: (app: any): void => {
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component);
    }
    app.use(ElementPlus, { locale: es, size: "small", zIndex: 3000 });
  },
};
