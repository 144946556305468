import { IPolitica } from "./../interfaces/usuarios";
import { IParametros, IResultado } from "@/interfaces/requests";
import { IRol } from "@/interfaces/roles";
import { http } from "@/utils/http";

export const obtenerRolesServicio = async ({
  pagina,
  limite,
}: IParametros): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/roles`, {
      params: { page: pagina, limit: limite },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerRolPorIdServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/roles/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
export const crearRolServicio = async (body: IRol): Promise<IResultado> => {
  try {
    const response = await http.post(`/tercero/roles`, body);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const actualizarRolServicio = async ({
  rol_id: id,
  ...body
}: IRol): Promise<IResultado> => {
  try {
    const response = await http.patch(`/tercero/roles/${id}`, body);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const eliminarRolServicio = async (id: number): Promise<IResultado> => {
  try {
    const response = await http.delete(`/tercero/roles/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerRolPorUsuarioServicio = async (): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/usuario/rol`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerPoliticaDatosServicio = async (): Promise<IResultado> => {
  try {
    const response = await http.get(`/politicas`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const enviarPoliticaDatosServicio = async (
  politicas: Pick<IPolitica, "politica_id">[]
): Promise<IResultado> => {
  try {
    const response = await http.post(`/politicas-lineas`, politicas);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
