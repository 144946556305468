import { ITipoDocumento } from "@/interfaces/base";
import { IActualizarEstado } from "@/interfaces/utilidades";
import useValoresEstaticos from "./valores-estaticos";
import { mostrarCargando, mostrarNotificacion } from "@/utils/notification";
import { IEstatus } from "@/interfaces/requests";
import { http } from "@/utils/http";

export default function useUtilidadesDocumentos() {
  const establecerTipoDocPorDefecto = <
    T extends Record<"tipo_documento_id", number | null>
  >(
    form: T,
    tipos: ITipoDocumento[]
  ) => {
    if (tipos.length === 1) {
      const [{ tipo_documento_id }] = tipos;
      if (tipo_documento_id) {
        form.tipo_documento_id = tipo_documento_id;
      }
    }
  };

  const eventoActualizarEstadoDocumento = async (
    parametros: IActualizarEstado
  ) => {
    try {
      const { rutas_estado } = useValoresEstaticos();
      mostrarCargando({ msg: "Actualizando estado..." });
      const respuesta = await http.patch(
        `${rutas_estado.get(parametros.ruta)}/${parametros.id}`,
        parametros.body
      );
      if (respuesta.data.statusCode !== IEstatus.OK) {
        throw new Error(respuesta.data.message);
      }
      mostrarNotificacion({
        title: "¡Excelente!",
        type: "success",
        message: respuesta.data.message,
      });
    } catch (error) {
      const e = error as Error;
      mostrarNotificacion({
        message: e.toString(),
      });
    } finally {
      mostrarCargando({ active: false });
    }
  };

  return {
    establecerTipoDocPorDefecto,
    eventoActualizarEstadoDocumento,
  };
}
