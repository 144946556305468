import {
  ITiposDocumentosBaseEnum,
  ListaCompuesta,
  ListasEstandarFormulario,
} from "@/interfaces/base";
import { EstadoDocumento } from "@/interfaces/remesas";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

export default function useValoresEstaticos() {
  const mostrar_contabilidad = ref(false);

  const rutas_estado = new Map([
    ["orden_cargue", "/orden-cargue/estado"],
    ["remesa", "/remesa-propietario/estado"],
    ["sop", "/servicios-otro-proveedor/estado"],
    ["manifiesto", "/manifiesto/estado"],
    ["facturas_notas", "/facturas-notas/estado"],
    ["anticipos", "/anticipos/estado"],
    ["nota_contable", "/nota-contable/estado"],
    ["egresos", "/egresos-recaudos/estado"],
    ["recaudos", "/egresos-recaudos/estado"],
    ["solicitud_facturacion", "/solicitud-facturacion/estado"],
    ["gasto", "/contabilidad/reporte-gastos/estado"],
    ["distribucion", "/distribucion-ingresos/estado"],
    ["asignacion", "/utilidades/asignacion/estado"],
    ["recepcion_entrega", "/inventario/recepcion-entrega/estado"],
  ]);

  const accion_url = computed<string>(
    () => `${import.meta.env.VITE_BASE_API}/utilidades/s3`
  );

  const estados_lista = ref<ListasEstandarFormulario[]>([
    { codigo: EstadoDocumento.BORRADOR, nombre: "Borrador" },
    { codigo: EstadoDocumento.COMPLETADO, nombre: "Completado" },
    { codigo: EstadoDocumento.ANULADO, nombre: "Anulado" },
  ]);

  const acciones_boton = ref<ListaCompuesta[]>([
    {
      codigo: EstadoDocumento.BORRADOR,
      deshabilitar: false,
      nombre: "Reactivar",
    },
    {
      codigo: EstadoDocumento.COMPLETADO,
      deshabilitar: false,
      nombre: "Completar",
    },
    {
      codigo: EstadoDocumento.ANULADO,
      deshabilitar: false,
      nombre: "Anular",
    },
  ]);

  const establecerNombreModulo = () => {
    const route = useRoute();
    const mapa = new Map([
      [ITiposDocumentosBaseEnum.CuentaPorCobrar, "cuenta por cobrar"],
      [ITiposDocumentosBaseEnum.NotaCreditoCxC, "nota crédito CXC"],
      [ITiposDocumentosBaseEnum.NotaDebitoCxC, "nota débito CXC"],
      [ITiposDocumentosBaseEnum.CuentaPorPagar, "cuenta por pagar"],
      [ITiposDocumentosBaseEnum.NotaCreditoCxP, "nota crédito CXP"],
      [ITiposDocumentosBaseEnum.NotaDebitoCxP, "nota débito CXP"],
      [ITiposDocumentosBaseEnum.Anticipo, "anticipo a proveedores"],
      [ITiposDocumentosBaseEnum.DocumentoSoporte, "documento soporte"],
      [ITiposDocumentosBaseEnum.Recepcion, "recepción"],
      [ITiposDocumentosBaseEnum.Entrega, "entrega"],
      ["auxiliar", "informe auxiliar"],
      ["auxiliar-terceros", "informe auxiliar terceros"],
      ["balance", "informe balance"],
      ["pyg", "informe PYG"],
      ["medios", "informe medios magneticos"],
      ["asientos-bancarios", "informe asientos bancarios"],
      ["sin-facturar", "informe manifiesto sin facturar"],
      ["sin-liquidar", "informe manifiesto sin liquidar"],
      ["manifiesto-sin-pago", "informe manifiesto sin pago"],
      ["general-manifiestos", "informe general manifiestos"],
      ["comisiones-conductores", "informe comisiones conductores"],
      ["retencion-ica", "informe retención ICA"],
      ["comisiones-comerciales", "informe comisiones comerciales"],
      ["ingresos-por-origen", "informe ingresos por origen"],
      ["informacion-contable", "informe información contable"],
      [undefined, route.name],
    ]);
    return mapa.get(route.query.tipo as ITiposDocumentosBaseEnum) as string;
  };

  const deshabilitarBoton = (estado: string) => {
    acciones_boton.value.forEach((boton) => {
      boton.deshabilitar =
        estado === EstadoDocumento.ANULADO ? true : boton.codigo === estado;
    });
  };

  return {
    rutas_estado,
    accion_url,
    estados_lista,
    acciones_boton,
    mostrar_contabilidad,
    establecerNombreModulo,
    deshabilitarBoton,
  };
}
