import { ElNotification, ElLoading } from "element-plus";

interface Loading {
  active?: boolean;
  msg?: string;
}
type StringType = "error" | "success" | "warning" | "info";
interface Notification {
  title?: string;
  message: string;
  type?: StringType;
  duration?: number;
}

const mostrarNotificacion = ({
  title = "Error",
  message,
  type = "error",
  duration = 0,
}: Notification): void => {
  ElNotification({
    customClass: 'alerta-notificacion',
    title,
    message,
    type,
    duration: type == "success" ? 3000 : duration,
  });
};

const mostrarCargando = ({ active = true, msg }: Loading): void => {
  const loading = ElLoading.service({
    lock: true,
    text: msg,
    background: "rgba(0,0,0,0.7)",
  });

  if (!active) loading.close();
};

export { mostrarNotificacion, mostrarCargando };
