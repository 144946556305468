import type { IBase } from "./base";

export interface ITercero extends IBase {
  tercero_id?: number;
  tipo_documento_id: number | null;
  numero_documento: string;
  digito_verificacion: number | null;
  nombre: string;
  nombre_2: string;
  primer_nombre: string;
  segundo_nombre: string;
  primer_apellido: string;
  segundo_apellido: string;
  observaciones: string;
  responsabilidad_fiscal: string;
  fe_tipo_regimen_id: number | null;
  fe_naturaleza_tercero_id: number | null;
  grupo_tercero_id: number | null;
  tipo_contribuyente_id: number | null;
  correo_electronico?: string;
  es_conductor: boolean;
  tipo_licencia_id: number | null;
  numero_licencia: string | null;
  fecha_vencimiento_licencia: string | null;
  fecha_nacimiento: string | null;
  foto?: string | null;
  vencimiento_seguridad_social: string | null;
  numero_planilla: string | null;
  autorizado: boolean;
  fecha_actualizacion_conductor: string | null;
  es_proveedor: boolean;
  es_empleado: boolean;
  forma_pago_cxp_id: number | null;
  forma_pago_cxc_id: number | null;
  termino_pago_cxp: number | null;
  es_cliente: boolean;
  termino_pago_cxc: number | null;
  limite_credito: number | null;
  credito_usado?: number | null;
  comercial: boolean;
  cuenta_niif_cxc_id: number | null;
  cuenta_niif_cxp_id: number | null;
  cuenta_niif_ap_cliente_id: number | null;
  cuenta_niif_ap_proveedor_id: number | null;
  cuenta_fiscal_cxc_id: number | null;
  cuenta_fiscal_cxp_id: number | null;
  cuenta_fiscal_ap_cliente_id: number | null;
  cuenta_fiscal_ap_proveedor_id: number | null;
  representante_comercial_id?: number;
  nombre_representante?: string;
  representante_comercial_nombre?: string;
  email_notificar_wms?: string;
}

export type TerceroType = keyof ITercero;

export type TerceroExtendido = ITercero & {
  cuenta_fiscal_ap_cliente: string;
  cuenta_fiscal_ap_proveedor: string;
  cuenta_fiscal_cxc: string;
  cuenta_fiscal_cxp: string;
  cuenta_niif_ap_cliente: string;
  cuenta_niif_ap_proveedor: string;
  cuenta_niif_cxc: string;
  cuenta_niif_cxp: string;
};

export type ListaTipo =
  | "tipos_documento"
  | "tipos_regimen"
  | "naturalezas"
  | "grupos_tercero"
  | "tipos_contribuyente"
  | "licencias"
  | "formas_pago";

export enum TerceroTipoEnum {
  CONDUCTOR = "es_conductor",
  PROVEEDOR = "es_proveedor",
  CLIENTE = "es_cliente",
  EMPLEADO = "es_empleado",
  COMERCIAL = "comercial",
  TODOS = "todos",
}
