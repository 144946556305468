import type {
  ICorreoComercial,
  IFacturaNota,
  IFacturaNotasLinea,
  ParametroFacturaNotas,
  IDocumentosRelacionados,
} from "@/interfaces/facturas-notas";
import type { IResultado } from "@/interfaces/requests";
import { http } from "@/utils/http";

export const obtenerFacturasNotassServicio = async ({
  tipo_base,
  pagina,
  limite,
  busqueda,
}: ParametroFacturaNotas) => {
  try {
    let params = { tipo_base, page: pagina, limit: limite } as any;

    if (busqueda) {
      Object.entries(busqueda).forEach(([clave, valor]) => {
        if (valor) {
          params[clave] = valor;
        }
      });
    }
    const response = await http.get(`/facturas-notas/tipo-base`, { params });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const obtenerFacturasNotasPorManifietoIdServicio = async (
  id: number
) => {
  try {
    const response = await http.get(`/facturas-notas/manifiesto/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};
export const obtenerFacturasNotasPorBusquedaServicio = async (
  nro_factura: string,
  esta_completado: boolean = false
) => {
  try {
    const response = await http.get(`/facturacion/busqueda`, {
      id: `factura-${nro_factura}`,
      cache: {
        ttl: 1000 * 60, // 1 minute.
      },
      params: { nro_factura, esta_completado },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const obtenerFacturasNotasPorIdServicio = async (id: number) => {
  try {
    const response = await http.get(`/facturas-notas/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const crearFacturasNotasServicio = async (
  factura_notas: IFacturaNota
) => {
  try {
    const response = await http.post(`/facturas-notas`, factura_notas);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const crearDocumentosRelacionadosServicio = async (
  datos: IDocumentosRelacionados
) => {
  try {
    const response = await http.post(
      `/facturas-notas/documentos-relacionados`,
      datos
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const crearCopiaDocumentoServicio = async (documento_id: number) => {
  try {
    const response = await http.post(`/facturas-notas/copia-documento`, {
      documento_id,
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const actualizarFacturasNotasServicio = async ({
  documento_id,
  ...factura_notas
}: any) => {
  try {
    const response = await http.patch(
      `/facturas-notas/${documento_id}`,
      factura_notas
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};
// ! lineas
export const obtenerFacturasNotasLineasPorIdServicio = async (id: number) => {
  try {
    const response = await http.get(`/facturas-notas-lineas/facturas-notas`, {
      params: { documento_id: id },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};
export const crearFacturasNotasLineasPorIdServicio = async (
  body: IFacturaNotasLinea
) => {
  try {
    const response = await http.post(`/facturas-notas-lineas`, body);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};
export const actualizarFacturasNotasLineasPorIdServicio = async ({
  documento_linea_id,
  ...body
}: IFacturaNotasLinea) => {
  try {
    const response = await http.patch(
      `/facturas-notas-lineas/${documento_linea_id}`,
      body
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};
export const eliminarFacturasNotasLineasPorIdServicio = async (id: number) => {
  try {
    const response = await http.delete(`/facturas-notas-lineas/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const obtenerFacturasSinPagarServicio = async ({
  proveedor_cliente_id,
  tipo_base,
}: Record<"proveedor_cliente_id" | "tipo_base", string | number>) => {
  try {
    const response = await http.get(
      `/facturas-notas/fac-sin-pagar/${proveedor_cliente_id}`,
      { params: { tipo_base } }
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const obtenerTiposDocumentosServicio = async (tipo_base: string) => {
  try {
    const response = await http.get(
      `/utilidades/tipo-documento/tipo-base/${tipo_base}`
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const obtenerTiposMotivoNotaServicio = async (tipo: string) => {
  try {
    const response = await http.get(`/facturas-notas/motivo-nota/tipo`, {
      params: { tipo },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};

export const enviarCorreoComercialesServicio = async (
  datos: ICorreoComercial
) => {
  try {
    const response = await http.post(`/facturas-notas/correo-comercial`, datos);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.toString());
  }
};
