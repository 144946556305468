import { ICompararFechas, ICompararValores } from "@/interfaces/utilidades";
import dayjs from "dayjs";
import router from "@/router";

export default function useCalculoComparaciones() {
  const calcularDigitoVerificacion = (document: string) => {
    const nrosPrimos = [
      3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71,
    ];
    const document_splited = document.split("");
    let sumTotal = 0;
    for (let i = 0; i < document_splited.length; i++) {
      const digit = Number(document_splited.at(-1 * (i + 1))); // !recorrer desde el ultimo
      const nroPrimo = nrosPrimos[i];
      sumTotal += digit * nroPrimo;
    }
    const resultModule = sumTotal % 11;
    let digit = 11 - resultModule;
    if (resultModule == 0 || resultModule == 1) {
      digit = resultModule;
    }
    return digit;
  };

  const compararFechas = ({
    fecha1,
    fecha2,
    tipo = "hour",
    callback,
    mensaje = "La fecha debe ser posterior a la actual",
  }: ICompararFechas) => {
    if (!dayjs(fecha1).isBefore(fecha2, tipo as dayjs.OpUnitType)) {
      callback(new Error(mensaje));
    }
    callback();
  };

  const compararValores = ({ cxc, cxp, callback }: ICompararValores) => {
    if (cxp > cxc) {
      callback(new Error("La CXP debe ser mayor a la CXC"));
    }
    callback();
  };

  const separarPorComas = (valor: number | null) => {
    if (!isNaN(valor as number)) {
      return valor?.toLocaleString("en-US", {
        maximumFractionDigits: 12,
        minimumFractionDigits: 2,
      });
    } else {
      return 0;
    }
  };

  const eventoCancelar = () => {
    router.go(-1);
  };

  return {
    calcularDigitoVerificacion,
    compararFechas,
    compararValores,
    separarPorComas,
    eventoCancelar,
  };
}
