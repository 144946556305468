import { http } from "@/utils/http";
import type { IParametros, IResultado } from "@/interfaces/requests";
import type { ITercero } from "@/interfaces/tercero";
import { IOpcionBuscador } from "@/interfaces/base";

export const obtenerTercerosServicio = async ({
  pagina,
  limite,
  busqueda,
}: IParametros<IOpcionBuscador>): Promise<IResultado> => {
  try {
    const params = { page: pagina, limit: limite } as any;

    if (busqueda) {
      params[`${busqueda.clave}`] = `${busqueda.valor}`;
    }
    const response = await http.get(`/tercero`, {
      params,
    });
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio obtenerTercerosServicio, detalle: \n${error}`
    );
  }
};

export const obtenerListaServicio = async (): Promise<IResultado[]> => {
  try {
    const response = await Promise.all([
      http.get(`/tercero/tipo-documento`),
      http.get(`/tercero/fe-tipo-regimen`),
      http.get(`/tercero/fe-naturaleza-tercero`),
      http.get(`/tercero/grupo-tercero`),
      http.get(`/tercero/tipo-contribuyente`),
      http.get(`/tercero/tipo-licencia`),
      http.get(`/tercero/forma-pago`),
    ]);
    const result = response?.map((r) => r.data) as IResultado[];
    return result;
  } catch (error) {
    throw new Error(
      `Error en el servicio obtenerListaServicio, detalle: \n${error}`
    );
  }
};

export const obtenerTipoContribuyenteServicio =
  async (): Promise<IResultado> => {
    try {
      const response = await http.get(`/tercero/tipo-contribuyente`);
      return response.data as IResultado;
    } catch (error) {
      throw new Error(
        `Error obtenerTipoContribuyenteServicio, detalle: \n${error}`
      );
    }
  };
export const obtenerSedesServicio = async (): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/sede`);
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio obtenerSedesServicio, detalle: \n${error}`
    );
  }
};

export const obtenerCompaniasServicio = async (): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/cliente`);
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio obtenerCompaniasServicio, detalle: \n${error}`
    );
  }
};
export const obtenerFormasPagoServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/forma-pago`, {
      params: { tercero_id: id },
    });
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio obtenerFormasPagoServicio, detalle: \n${error}`
    );
  }
};

export const formasPagoServicio = async (): Promise<IResultado> => {
  const response = await http.get(`/tercero/forma-pago`);
  return response?.data as IResultado;
};

export const obtenerTerceroPorIdServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/${id}`);
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio obtenerTerceroPorIdServicio, detalle: \n${error}`
    );
  }
};

export const obtenerTerceroPorBusquedaServicio = async (
  docNombre: string,
  tipo?: string,
  excluirInactivos: boolean = false
): Promise<IResultado> => {
  try {
    const tipo_por_defecto = "es_cliente";
    const params = tipo
      ? { docNombre, tipo, excluirInactivos }
      : { docNombre, tipo: tipo_por_defecto, excluirInactivos };

    const response = await http.get(`/tercero/busqueda`, {
      id: `tercero-${docNombre}-${tipo}-${excluirInactivos}`,
      cache: {
        ttl: 1000 * 60, // 1 minute.
      },
      params,
    });
    return response?.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio obtenerTerceroPorBusquedaServicio, detalle: \n${error}`
    );
  }
};

export const crearTercerosServicio = async (
  tercero: Partial<ITercero>
): Promise<IResultado> => {
  try {
    const response = await http.post(`/tercero`, tercero);
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio crearTercerosServicio, detalle: \n${error}`
    );
  }
};

export const actualizarTerceroServicio = async ({
  tercero_id,
  ...body
}: Partial<ITercero>): Promise<IResultado> => {
  try {
    const response = await http.patch(`/tercero/${tercero_id}`, body);
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio actualizarTerceroServicio, detalle: \n${error}`
    );
  }
};

export const borrarTerceroPorIdServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.delete(`/tercero/${id}`);
    return response.data as IResultado;
  } catch (error) {
    throw new Error(
      `Error en el servicio borrarTerceroPorIdServicio, detalle: \n${error}`
    );
  }
};
