<script setup lang="ts">
import { onBeforeMount, reactive, ref, toRefs, watch } from "vue";
import type { ElForm, FormInstance, FormRules } from "element-plus";
import FondoTransportando from "@/components/logo/bg-transportando.vue";
import LogoTransportando from "@/components/logo/logo-transportando.vue";
import { useUsuarioStore } from "@/stores/usuarios";
import { ILogin } from "@/interfaces/usuarios";
import TerminosCondiciones from "@/components/terminos-condiciones/terminos-condiciones.vue";

const recordar_datos = ref(false);
const form_ref = ref<FormInstance>();
const form = reactive<ILogin>({
  nombre: "",
  contrasena: "",
  documento: "",
});

const rules = ref<FormRules>({
  nombre: [
    {
      required: true,
      message: "El usuario es requerido",
      trigger: "blur",
    },
  ],
  contrasena: [
    {
      required: true,
      message: "La contraseña es requerida",
      trigger: "blur",
    },
  ],
  documento: [
    {
      required: true,
      message: "El documento es requerida",
      trigger: "blur",
    },
  ],
});

const store = useUsuarioStore();

const onEnviar = (form_el: FormInstance | undefined): void | boolean => {
  if (!form_el) return;
  form_el.validate(async (valid) => {
    if (!valid) return false;
    await store.inicioSesionAccion({ ...form });
  });
};

const establcerRecordarDatos = () => {
  const { nombre, documento, recordar } = JSON.parse(
    localStorage.getItem("recordar") ?? "{}"
  );

  if (recordar) {
    form.nombre = nombre;
    form.documento = documento;
    recordar_datos.value = recordar;
  }
};

onBeforeMount(() => {
  establcerRecordarDatos();
});

watch(
  [recordar_datos, () => form],
  ([nuevo_valor]) => {
    if (nuevo_valor) {
      localStorage.setItem(
        "recordar",
        JSON.stringify({
          nombre: form.nombre,
          documento: form.documento,
          recordar: recordar_datos.value,
        })
      );
    }
  },
  { deep: true }
);

const { nombre, contrasena, documento } = toRefs(form);
</script>

<template>
  <div class="sign-in">
    <TerminosCondiciones />
    <FondoTransportando />
    <div class="sign-in-content">
      <el-card shadow="always" :body-style="{ padding: '20px' }">
        <template #header>
          <LogoTransportando class="card-logo" />
        </template>
        <!-- card body -->
        <el-form
          :model="form"
          ref="form_ref"
          :rules="rules"
          label-width="80px"
          label-position="top"
          status-icon
        >
          <el-form-item label="NIT:" class="sign-in-label" prop="documento">
            <el-input v-model="documento" type="text" id="documento"></el-input>
          </el-form-item>
          <el-form-item label="Usuario:" class="sign-in-label" prop="nombre">
            <el-input v-model="nombre" type="text" id="nombre"></el-input>
          </el-form-item>
          <el-form-item
            label="Contraseña:"
            class="sign-in-label"
            prop="contrasena"
          >
            <el-input
              v-model="contrasena"
              type="password"
              id="contrasena"
            ></el-input>
          </el-form-item>
          <el-form-item label="" class="sign-in-label">
            <el-checkbox
              v-model="recordar_datos"
              :indeterminate="false"
              style="color: #81d742"
            >
              Recordar datos</el-checkbox
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onEnviar(form_ref)"
              >Iniciar Sesión</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <div class="sign-in-footer">
      <span>O.S Group v 1.0.0</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

@mixin sign-in-mixin {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.4rem;
  box-sizing: border-box;
  z-index: 1;
}

.sign-in {
  @include container-aligned;

  .sign-in-content {
    @include container-aligned(50vw, auto);
    @include sign-in-mixin;
    transform: translate(-50%, -50%);
    border: 2px solid var(--bg-primary);
    border-radius: 0.5rem;
    left: 50%;
    top: 50%;

    .el-card {
      background-color: rgba(0, 0, 0, 0.7);
      @include container-aligned(50vw, 500px, column);

      .card-logo {
        width: 10vw;
        left: 50%;
        transform: translateX(-50%);
      }

      .el-form {
        width: 50%;
        margin: 0 auto;

        .el-form-item {
          .el-button {
            font-size: 1rem;
            padding: 0.9rem;
            margin: 2rem auto;
          }
        }
      }
    }
  }

  .sign-in-footer {
    @include container-aligned(100vw, 3vh);
    @include sign-in-mixin;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.5);
    bottom: 0;
  }
}
</style>
