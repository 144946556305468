import { mostrarNotificacion } from "@/utils/notification";
import { UploadProps } from "element-plus";

export default function useSubirImagenes() {
  const eventoImagenExitoso: UploadProps["onSuccess"] = (response) => {
    mostrarNotificacion({
      title: "¡Excelente!",
      type: "success",
      message: response.message,
    });
  };

  const eventoImagenError: UploadProps["onError"] = (error) => {
    mostrarNotificacion({
      message:
        JSON.parse(error.message)?.message ??
        "Hubo un error subiendo el archivo",
    });
  };

  const antesSubirImagen: UploadProps["beforeUpload"] = (rawFile) => {
    if (rawFile.size / 1000 > 100) {
      mostrarNotificacion({
        message: `El archivo supera el tamaño de 100KB!`,
      });
      return false;
    }
    return true;
  };

  return {
    eventoImagenExitoso,
    eventoImagenError,
    antesSubirImagen,
  };
}
