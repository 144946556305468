<template>
  <el-image :src="background_image" fit="cover" :lazy="true">
    <template #placeholder>
      <div>Cargando ...</div>
    </template>
    <template #error>
      <div>Logo ...</div>
    </template>
  </el-image>
</template>

<script setup lang="ts">
import { ref } from "vue";
import logo from "@/assets/logo-transportando.svg";

const background_image = ref<string>(logo);
</script>
