export interface IResultado {
  statusCode: number;
  message: string;
  data: { [key in string]: any };
}

export interface IParametros<T = {}> {
  pagina: number;
  limite: number;
  busqueda?: T;
}

export enum IEstatus {
  OK = 200,
  CREATED = 201,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  ERROR = 400,
  NOT_FOUND = 404,
}
