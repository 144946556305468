import axios, { type AxiosInstance } from "axios";
import { buildWebStorage, setupCache } from "axios-cache-interceptor";

const baseURL = import.meta.env.VITE_BASE_API;

const instancia: AxiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  validateStatus: () => true,
});

export const http = setupCache(instancia, {
  storage: buildWebStorage(sessionStorage, "axios-cache:"),
  ttl: 2000,
});

export const validarErrorStore = (error: Error): boolean => {
  return error?.message.includes("Cannot read properties of undefined");
};
