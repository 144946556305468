import { createApp } from "vue";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

import element_plus from "@/plugins/element-ui";
import interceptor from "@/plugins/interceptor";

import App from "./App.vue";
import router from "./router";

const app = createApp(App);
const pinia = createPinia();

app.use(interceptor);
app.use(element_plus);
app.use(pinia);

app.use(router);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_DSN_SENTRY,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/.*/,
      ],
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.mount("#app");

export default app;
