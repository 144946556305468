import { http } from "@/utils/http";
import type { IParametros, IResultado } from "@/interfaces/requests";
import type { IProducto, TipoProductoEmum } from "@/interfaces/producto";
import { IOpcionBuscador } from "@/interfaces/base";

export const obtenerProductosServicio = async ({
  pagina,
  limite,
  busqueda,
}: IParametros<IOpcionBuscador>): Promise<IResultado> => {
  try {
    const params = { page: pagina, limit: limite } as any;
    if (busqueda) {
      params[`${busqueda.clave}`] = `${busqueda.valor}`;
    }
    const response = await http.get(`/utilidades/producto`, { params });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const crearProductoServicio = async (
  body: IProducto
): Promise<IResultado> => {
  try {
    const response = await http.post(`/utilidades/producto`, body);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
export const obtenerProductoPorIdServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.get(`/utilidades/producto/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
export const actualizarProductoServicio = async ({
  producto_id: id,
  ...body
}: IProducto): Promise<IResultado> => {
  try {
    const response = await http.patch(`/utilidades/producto/${id}`, body);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
export const eliminarProductoServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.delete(`/utilidades/producto/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerProductoPorNombreServicio = async (
  nombre_codigo: string,
  tipo_producto: TipoProductoEmum
): Promise<IResultado> => {
  try {
    const response = await http.get(`/utilidades/producto/busqueda`, {
      id: `producto-${nombre_codigo}-${tipo_producto}`,
      cache: {
        ttl: 1000 * 60, // 1 minute.
      },
      params: { nombre_codigo, tipo_producto },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
