import useSubirImagenes from "./subir-imagenes";
import useValoresEstaticos from "./valores-estaticos";
import useCalculoComparaciones from "./calculo-comparaciones";
import useBusquedasRemotas from "./busquedas-remotas";
import useInformacionUsuario from "./informacion-usuario";
import useUtilidadesDocumentos from "./utilidades-documentos";

export default function useUtilidades() {
  return {
    ...useSubirImagenes(),
    ...useValoresEstaticos(),
    ...useCalculoComparaciones(),
    ...useBusquedasRemotas(),
    ...useInformacionUsuario(),
    ...useUtilidadesDocumentos(),
  };
}
