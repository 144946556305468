import { defineStore } from "pinia";
import { IEstatus, IParametros } from "@/interfaces/requests";
import { mostrarCargando, mostrarNotificacion } from "@/utils/notification";
import { validarErrorStore } from "@/utils/http";
import {
  actualizarUsuarioServicio,
  crearUsuarioServicio,
  eliminarUsuarioServicio,
  iniciarSesionServicio,
  obtenerUsuarioPorIdServicio,
  obtenerUsuariosServicio,
  obtenerNombreUsuarioServicio,
} from "@/services/usuarios";
import {
  enviarPoliticaDatosServicio,
  obtenerPoliticaDatosServicio,
  obtenerRolPorUsuarioServicio,
} from "@/services/roles";
import type { ILogin, IPolitica, IUsuario } from "@/interfaces/usuarios";
import router from "@/router";
import { computed, ref } from "vue";
import { resolverRutaPorPermisos } from "@/utils/manejador-rutas";
import { IOpcionBuscador } from "@/interfaces/base";

export const useUsuarioStore = defineStore("usuario", () => {
  const politicas = ref<IPolitica[]>([]);
  const usuarios = ref<IUsuario[]>([]);
  const todos_usuarios = computed<IUsuario[]>(() => usuarios.value);
  const usuario = ref<IUsuario>();
  const info_usuario = computed<IUsuario>(() => usuario.value!);
  const conteo = ref<number>(0);
  const total = computed<number>(() => conteo.value);
  const lista_politicas = computed(() => politicas.value);

  async function inicioSesionAccion(usuario: ILogin) {
    try {
      mostrarCargando({ msg: "Iniciando sesión ..." });
      const respuesta = await iniciarSesionServicio(usuario);

      if (respuesta.statusCode !== IEstatus.OK) {
        throw new Error(respuesta?.message ?? "Error de conexión");
      }
      sessionStorage.setItem("token", respuesta.data?.access_token as string);
      await obtenerPoliticasPendienteAccion();
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error, detalle: ${e.message}`,
      });
    } finally {
      mostrarCargando({ active: false });
    }
  }
  async function obtenerPoliticasPendienteAccion() {
    try {
      const respuesta = await obtenerPoliticaDatosServicio();

      if (respuesta.statusCode !== IEstatus.OK) {
        throw new Error(respuesta?.message ?? "Error de conexión");
      }

      if (respuesta.data.some((item: any) => item.aceptado === false)) {
        politicas.value = respuesta.data as IPolitica[];
      } else {
        await obtenerRolPorUsuarioAccion();
      }
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error, detalle: ${e.message}`,
      });
    }
  }
    async function obtenerPoliticasAceptadasAccion() {
      mostrarCargando({ msg: "Obteniendo datos ..." });
      try {
        const respuesta = await obtenerPoliticaDatosServicio();

        if (respuesta.statusCode !== IEstatus.OK) {
          throw new Error(respuesta?.message ?? "Error de conexión");
        }
        politicas.value = respuesta.data as IPolitica[];
      } catch (error) {
        const e: Error = error as Error;
        if (validarErrorStore(e)) return;
        mostrarNotificacion({
          message: `Error, detalle: ${e.message}`,
        });
      } finally {
        mostrarCargando({ active: false });
      }
    }
  async function enviarPoliticaDatosAccion(
    politicas: Pick<IPolitica, "politica_id">[]
  ) {
    try {
      const respuesta = await enviarPoliticaDatosServicio(politicas);

      if (respuesta.statusCode !== IEstatus.OK) {
        throw new Error(respuesta?.message ?? "Error de conexión");
      }
      await obtenerRolPorUsuarioAccion();
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error, detalle: ${e.message}`,
      });
    }
  }

  async function obtenerRolPorUsuarioAccion() {
    try {
      const datos_rol = await obtenerRolPorUsuarioServicio();
      resolverRutaPorPermisos(datos_rol);
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error, detalle: ${e.message}`,
      });
    }
  }

  const obtenerUsuariosAccion = async (parametros: IParametros<IOpcionBuscador>) => {
    try {
      mostrarCargando({ msg: "Obteniendo usuarios ..." });
      const response = await obtenerUsuariosServicio(parametros);
      if (response.statusCode === IEstatus.OK) {
        usuarios.value = response.data[0];
        conteo.value = response.data[1];
      }
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error obteniendo usuarios, detalle: ${e.message}`,
      });
    } finally {
      mostrarCargando({ active: false });
    }
  };

  const obtenerUsuarioPorIdAccion = async (id: number) => {
    try {
      mostrarCargando({ msg: "Obteniendo usuario ..." });
      const response = await obtenerUsuarioPorIdServicio(id);

      if (response.statusCode === IEstatus.OK) {
        usuario.value = response.data as IUsuario;
      }
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error obteniendo la usuario, detalle: ${e.message}`,
      });
    } finally {
      mostrarCargando({ active: false });
    }
  };

  const crearUsuarioAccion = async (form: IUsuario) => {
    try {
      mostrarCargando({ msg: "Creando usuario ..." });
      const response = await crearUsuarioServicio(form);

      if (response.statusCode !== IEstatus.CREATED) {
        throw new Error(response.message);
      }
      mostrarNotificacion({
        title: "¡Excelente!",
        type: "success",
        message: response.message,
      });
      router.replace({
        path: `/usuarios/update/${response.data as any}`,
      });
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error creando usuario, detalle: ${e.message}`,
      });
    } finally {
      mostrarCargando({ active: false });
    }
  };

  const actualizarUsuarioAccion = async (form: IUsuario) => {
    try {
      mostrarCargando({ msg: "Actualizando usuario ..." });
      const response = await actualizarUsuarioServicio(form);

      if (response.statusCode !== IEstatus.OK) {
        throw new Error(response.message);
      }
      mostrarNotificacion({
        title: "¡Excelente!",
        type: "success",
        message: response.message,
      });
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error actualizando usuario, detalle: ${e.message}`,
      });
    } finally {
      mostrarCargando({ active: false });
    }
  };

  const eliminarUsuarioAccion = async (id: number) => {
    try {
      mostrarCargando({ msg: "Eliminando usuario ..." });
      const response = await eliminarUsuarioServicio(id);

      if (response.statusCode !== IEstatus.OK) {
        throw new Error(response.message);
      }
      mostrarNotificacion({
        title: "¡Excelente!",
        type: "success",
        message: response.message,
      });
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error eliminando usuario, detalle: ${e.message}`,
      });
    } finally {
      mostrarCargando({ active: false });
    }
  };

  const obtenerNombreUsuarioAccion = async (nombre: string) => {
    try {
      const response = await obtenerNombreUsuarioServicio(nombre);

      if (response.statusCode !== IEstatus.OK) {
        throw new Error(response.message);
      }
    } catch (error) {
      const e: Error = error as Error;
      if (validarErrorStore(e)) return;
      mostrarNotificacion({
        message: `Error obteniendo nombre usuario, detalle: ${e.message}`,
      });
    }
  };

  return {
    todos_usuarios,
    total,
    info_usuario,
    lista_politicas,
    inicioSesionAccion,
    obtenerUsuariosAccion,
    obtenerUsuarioPorIdAccion,
    crearUsuarioAccion,
    actualizarUsuarioAccion,
    eliminarUsuarioAccion,
    obtenerNombreUsuarioAccion,
    enviarPoliticaDatosAccion,
    obtenerPoliticasAceptadasAccion,
  };
});
