import { IAtributos } from "@/interfaces/utilidades";
import { mostrarNotificacion } from "@/utils/notification";

export default function useInformacionUsuario() {
  const obtenerInfoUsuario = (): IAtributos => {
    const token = sessionStorage.getItem("token");
    const info_rol = sessionStorage.getItem("complementarios");
    const base64 = token?.split(".")?.[1];
    const variables = atob(base64 ?? "");
    const resultado = variables ? JSON.parse(variables) : {};
    const datos_rol = info_rol ? JSON.parse(info_rol) : {};
    return { ...resultado, ...datos_rol };
  };

  const validarPermiso = (permiso: string) => {
    const atributos = obtenerInfoUsuario();
    const permisos = atributos.permisos;
    const es_usuario_admin = atributos?.es_admin_cliente || atributos?.es_super;

    if (es_usuario_admin) {
      return true;
    }

    const es_permitido = permisos.some((valor) => {
      return valor.id === permiso;
    });

    if (!es_permitido) {
      mostrarNotificacion({ message: "No tiene accesos suficientes" });
      return false;
    } else {
      return true;
    }
  };

  const validarCuentaContable = (cuenta: string): string => {
    const atributos = obtenerInfoUsuario();
    const cuenta_contable_obligatoria = atributos?.cuenta_contable_obligatoria;

    return cuenta_contable_obligatoria ? cuenta : "";
  };

  return {
    obtenerInfoUsuario,
    validarPermiso,
    validarCuentaContable,
  };
}
