import type { App } from "vue";
import router from "@/router";
import type { AxiosResponse, AxiosInstance } from "axios";
import { http } from "@/utils/http";
import { mostrarNotificacion } from "@/utils/notification";

export default {
  install: (app: App): void => {
    app.config.globalProperties.$http = http as AxiosInstance;
    const $http = app.config.globalProperties.$http;

    const token = sessionStorage.getItem("token");

    if (token) {
      $http.defaults.headers.common.Authorization = token;
    }

    const manejadorRespuesta = (response: AxiosResponse) => {
      if (response.data.data?.access_token) {
        $http.defaults.headers.common.Authorization =
          response.data.data.access_token;
      }
      if (response.status === 401) {
        mostrarNotificacion({
          title: "Error",
          message: `Error, detalle: ${response.data?.message}`,
          type: "error",
        });
        return router.push({ path: "/" });
      }
      return response;
    };

    const manejadorError = (err: Error) => {
      if (err instanceof Error) {
        mostrarNotificacion({
          title: "Error",
          message: `Error de conexión, detalle: ${err}`,
          type: "error",
        });
        router.push({ path: "/" });
      }
    };

    $http.interceptors.response.use(manejadorRespuesta, manejadorError);
  },
};
