import { http } from "@/utils/http";
import type { ILogin, IUsuario } from "@/interfaces/usuarios";
import { IParametros, IResultado } from "@/interfaces/requests";
import { IOpcionBuscador } from "@/interfaces/base";

export const iniciarSesionServicio = async (body: ILogin) => {
  try {
    const response = await http.post(`/autenticacion/login/`, body);
    return response.data;
  } catch (err) {
    const error = err as Error;
    return { status: 400, message: error.message };
  }
};

export const obtenerUsuariosServicio = async ({
  pagina,
  limite,
  busqueda
}: IParametros<IOpcionBuscador>): Promise<IResultado> => {
  try {
    const params = { page: pagina, limit: limite } as any;

    if (busqueda) {
      params[`${busqueda.clave}`] = `${busqueda.valor}`;
    }
    const response = await http.get(`/tercero/usuarios`, {
      params
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const crearUsuarioServicio = async (
  body: IUsuario
): Promise<IResultado> => {
  try {
    const response = await http.post(`/tercero/usuarios`, body);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerUsuarioPorIdServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/usuarios/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
export const actualizarUsuarioServicio = async ({
  usuario_id: id,
  ...body
}: IUsuario): Promise<IResultado> => {
  try {
    const response = await http.patch(`/tercero/usuarios/${id}`, body);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
export const eliminarUsuarioServicio = async (
  id: number
): Promise<IResultado> => {
  try {
    const response = await http.delete(`/tercero/usuarios/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerNombreUsuarioServicio = async (
  nombre: string
): Promise<IResultado> => {
  try {
    const response = await http.get(`/tercero/usuarios/nombre/${nombre}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
