<template>
  <img :src="background_image" alt="transportando" srcset="" />
</template>

<script setup lang="ts">
import { ref } from "vue";
import transportando from "@/assets/transportando.svg";

const background_image = ref<string>(transportando);
</script>

<style lang="scss" scoped>
@import "@/styles/mixins";

img {
  opacity: 0.4;
}
</style>
