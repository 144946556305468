import { http } from "@/utils/http";
import type { IAnularManifiesto, IManifiesto, TipoTransporteEnum } from "@/interfaces/manifiesto";
import type { IParametros, IResultado } from "@/interfaces/requests";

export const obtenerManifiestosServicio = async ({
  pagina,
  limite,
  busqueda,
}: IParametros) => {
  try {
    let params = { page: pagina, limit: limite } as any;

    if (busqueda) {
      Object.entries(busqueda).forEach(([clave, valor]) => {
        if (valor) {
          params[clave] = valor;
        }
      });
    }
    const response = await http.get(`/manifiesto`, { params });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerManifiestoPorIdServicio = async (id: number) => {
  try {
    const response = await http.get(`/manifiesto/${id}`);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerManifiestoPorBusquedaServicio = async (
  nro_manifiesto: string
) => {
  try {
    const response = await http.get(`/manifiesto/busqueda`, {
      id: `manifiesto-${nro_manifiesto}`,
      cache: {
        ttl: 1000 * 60, // 1 minute.
      },
      params: {
        nro_manifiesto,
      },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const obtenerManifiestoPorOrdenServicio = async (id: number) => {
  try {
    const response = await http.get(`/manifiesto/orden-cargue`, {
      params: { orden_cargue_id: id },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const crearManifiestoServicio = async (manifiesto: IManifiesto) => {
  try {
    const response = await http.post(`/manifiesto`, manifiesto);
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const actualizarManifiestoServicio = async ({
  manifiesto_id,
  ...manifiesto
}: IManifiesto) => {
  try {
    const response = await http.patch(
      `/manifiesto/${manifiesto_id}`,
      manifiesto
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const busquedaCiudadServicio = async (
  busqueda: string
): Promise<IResultado> => {
  try {
    const response = await http.get(`/manifiesto/ciudad/busqueda`, {
      params: { NombreCiudad: busqueda },
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const busquedaRutasServicio = async (
  ciudad_origen_id: number,
  ciudad_destino_id: number
): Promise<IResultado> => {
  try {
    const respuesta = await http.get(`/rutas/busqueda/`, {
      params: { ciudad_origen_id, ciudad_destino_id },
    });
    return respuesta.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
export const obtenerTiposDocumentoServicio = async (): Promise<IResultado> => {
  try {
    const respuesta = await http.get(`/utilidades/tipo-documento/`);
    return respuesta.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const autorizarManifiestoServicio = async ({
  id,
  tipo_transporte,
}: {
  id: number;
  tipo_transporte: TipoTransporteEnum | null;
}) => {
  try {
    const response = await http.patch(`/manifiesto/autorizar/${id}`, {
      tipo_transporte,
    });
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const cumplirManifiestoServicio = async ({
  manifiesto_id,
  ...body
}: any) => {
  try {
    const response = await http.patch(
      `/manifiesto/cumplir-manifiesto/${manifiesto_id}`,
      body
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const anularCumplidoManifiestoServicio = async ({
  manifiesto_id,
  ...body
}: IAnularManifiesto) => {
  try {
    const response = await http.patch(
      `/manifiesto/anular-cumplido-manifiesto/${manifiesto_id}`,
      body
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const enviarSMSServicio = async (id: number): Promise<IResultado> => {
  try {
    const respuesta = await http.post(`/utilidades/sms/manifiestos/${id}`);
    return respuesta.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};

export const anularManifiestoServicio = async ({
  manifiesto_id,
  ...datos
}: IAnularManifiesto) => {
  try {
    const response = await http.patch(
      `/manifiesto/anular-manifiesto/${manifiesto_id}`,
      datos
    );
    return response.data as IResultado;
  } catch (error) {
    const e = error as Error;
    throw new Error(e.message);
  }
};
