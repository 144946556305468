<script setup lang="ts">
import { reactive, ref, onBeforeMount, watch, watchEffect } from "vue";
import { useUsuarioStore } from "@/stores/usuarios";
import { storeToRefs } from "pinia";
import { mostrarNotificacion } from "@/utils/notification";

const props = withDefaults(
  defineProps<{
    deshabilitar?: boolean;
  }>(),
  {
    deshabilitar: false,
  }
);

const store = useUsuarioStore();
const { lista_politicas } = storeToRefs(store);

const deshabilitar_boton = ref(false);
const dialogo_abiero = ref(false);
const contenido = ref("");
const contenido2 = ref("");

const form = reactive({
  Tratamiento_de_datos_personales: {
    mensaje: "",
    aceptado: false,
  },
  Terminos_y_Condiciones: {
    mensaje: "",
    aceptado: false,
  },
});

const obtenerHtml = async () => {
  const resultados = await Promise.all([
    fetch(
      "https://s3.us-east-1.amazonaws.com/prod.us-east-1.tms-backend.imagenes.transportando.com.co/termino_condiciones.html"
    ),
    fetch(
      "https://s3.us-east-1.amazonaws.com/prod.us-east-1.tms-backend.imagenes.transportando.com.co/tratamientos_datos.html"
    ),
  ]);
  const [terminos, tratamiento] = resultados;
  contenido.value = await terminos?.text();
  contenido2.value = await tratamiento?.text();
};

const enviarPolitica = async () => {
  const es_valido = Object.values(form).every((item) => item.aceptado === true);

  if (es_valido) {
    const lista = lista_politicas.value.map((item) => ({
      politica_id: item.politica_id,
    }));
    await store.enviarPoliticaDatosAccion(lista);
    dialogo_abiero.value = false;
  } else {
    mostrarNotificacion({
      message: "Debe aceptar las políticas",
    });
  }
};

onBeforeMount(async () => {
  await obtenerHtml();
});

watch(lista_politicas, (nuevo_valor) => {
  if (nuevo_valor.length) {
    const variables = nuevo_valor.reduce((acum, item) => {
      acum[item.tipo_politica] = {
        mensaje: item.aceptado
          ? `Versión ${item.version} de ${item.creado} aceptada el ${item.fecha_hora_aceptacion}`
          : `Versión ${item.version} de ${item.creado} sin aceptar`,
        aceptado: item.aceptado,
      };
      return acum;
    }, {} as any);

    form.Terminos_y_Condiciones = variables.Terminos_y_Condiciones;
    form.Tratamiento_de_datos_personales =
      variables.Tratamiento_de_datos_personales;
    dialogo_abiero.value = true;
  }
});

watchEffect(()=>{
  deshabilitar_boton.value = props.deshabilitar;
})
</script>

<template>
  <el-dialog
    title="Aceptación de términos y tratamiento de datos"
    v-model="dialogo_abiero"
    width="50%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
  >
    <div class="politica-datos" v-html="contenido"></div>
    <span>{{ form.Terminos_y_Condiciones.mensaje }}</span>
    <el-row :gutter="20">
      <el-col :span="12" :offset="0">
        <el-checkbox
          v-model="form.Terminos_y_Condiciones.aceptado"
          label="¿Acepto los términos y condiciones?"
          size="large"
        />
      </el-col>
    </el-row>
    <div class="politica-datos" v-html="contenido2"></div>
    <span>{{ form.Tratamiento_de_datos_personales.mensaje }}</span>
    <el-row :gutter="20">
      <el-col :span="12" :offset="0">
        <el-checkbox
          v-model="form.Tratamiento_de_datos_personales.aceptado"
          label="¿Acepto la política de tratamiento de datos?"
          size="large"
        />
      </el-col>
    </el-row>
    <template #footer>
      <el-row :gutter="20" justify="end">
        <el-col :span="3" :offset="0">
          <el-button
            :disabled="deshabilitar_boton"
            @click="dialogo_abiero = false"
            >Cancelar</el-button
          >
        </el-col>
        <el-col :span="3" :pull="1">
          <el-button
            :disabled="deshabilitar_boton"
            type="primary"
            @click="enviarPolitica"
            >Aceptar</el-button
          >
        </el-col>
      </el-row>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.politica-datos {
  border: 2px solid rgb(219, 219, 220);
  border-radius: 5px;
  box-sizing: content-box;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 20vh;
  margin-bottom: 0.5rem;
}
</style>
